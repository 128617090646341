// Generated by Framer (89417f6)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["kSfUKMUKj", "XR93qeM4g"];

const variantClassNames = {kSfUKMUKj: "framer-v-fq2wf4", XR93qeM4g: "framer-v-qmeno5"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "kSfUKMUKj", "Variant 2": "XR93qeM4g"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "kSfUKMUKj"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "kSfUKMUKj", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnterxnhb2f = activeVariantCallback(async (...args) => {
setVariant("XR93qeM4g")
})

const onMouseLeave13e3qjh = activeVariantCallback(async (...args) => {
setVariant("kSfUKMUKj")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-RCRGK", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-fq2wf4", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"kSfUKMUKj"} ref={ref} style={{backgroundColor: "rgb(51, 170, 255)", ...style}} variants={{XR93qeM4g: {backgroundColor: "rgb(255, 51, 228)"}}} {...addPropertyOverrides({XR93qeM4g: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1h4jsq8"} layoutDependency={layoutDependency} layoutId={"MaPvnnBbw"}><motion.div className={"framer-wzq1q8"} data-highlight layoutDependency={layoutDependency} layoutId={"p9EM8tBxR"} onMouseEnter={onMouseEnterxnhb2f} style={{backgroundColor: "rgb(187, 221, 255)"}} {...addPropertyOverrides({XR93qeM4g: {onMouseLeave: onMouseLeave13e3qjh}}, baseVariant, gestureVariant)}/><motion.div className={"framer-8larjx"} layoutDependency={layoutDependency} layoutId={"PcxcJ9fML"} style={{backgroundColor: "rgb(204, 238, 255)"}}/><motion.div className={"framer-1dp4xk9"} layoutDependency={layoutDependency} layoutId={"dyzWdox9I"} style={{backgroundColor: "rgb(204, 238, 255)"}}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-RCRGK [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-RCRGK .framer-1f66xs0 { display: block; }", ".framer-RCRGK .framer-fq2wf4 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 800px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 1200px; }", ".framer-RCRGK .framer-1h4jsq8 { align-content: center; align-items: center; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 520px; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 996px; }", ".framer-RCRGK .framer-wzq1q8, .framer-RCRGK .framer-8larjx, .framer-RCRGK .framer-1dp4xk9 { flex: 1 0 0px; height: 100%; position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-RCRGK .framer-fq2wf4, .framer-RCRGK .framer-1h4jsq8 { gap: 0px; } .framer-RCRGK .framer-fq2wf4 > *, .framer-RCRGK .framer-1h4jsq8 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-RCRGK .framer-fq2wf4 > :first-child, .framer-RCRGK .framer-1h4jsq8 > :first-child { margin-left: 0px; } .framer-RCRGK .framer-fq2wf4 > :last-child, .framer-RCRGK .framer-1h4jsq8 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 800
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"XR93qeM4g":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables false
 */
const FramerA969iHIuW: React.ComponentType<Props> = withCSS(Component, css, "framer-RCRGK") as typeof Component;
export default FramerA969iHIuW;

FramerA969iHIuW.displayName = "C";

FramerA969iHIuW.defaultProps = {height: 800, width: 1200};

addPropertyControls(FramerA969iHIuW, {variant: {options: ["kSfUKMUKj", "XR93qeM4g"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerA969iHIuW, [])